import React from "react";
import Carousel from "react-bootstrap/Carousel";
import TruckBanner from "../../assets/TruckBanner.jpg";
import TruckBanner2 from "../../assets/TruckBanner2.jpg";
import TruckBanner3 from "../../assets/TruckBanner3.jpg";
import aboutBanner from "../../assets/aboutBanner.jpg";
import aboutBanner2 from "../../assets/aboutBanner2.png";

import ngclogo from "../../assets/ngclogo.png";
import "../css/home.css";

const BannerCarousel = () => {
  const bannerData = [
    {
      img: aboutBanner2,
      title: "",
      text: "NGC Express Logistics Inc. provides sophisticated and integrated transportation solutions directed to the needs of various logistics industries",
    },
    {
      img: TruckBanner3,
      title: "",
      text: "NGC Express Logistics Inc. provides sophisticated and integrated transportation solutions directed to the needs of various logistics industries",
    },
    {
      img: TruckBanner2,
      //   title: "Logistics Services",
      title: ngclogo,
      text: "NGC Express Logistics Inc. envisions itself to be a trusted leading firm in logistics industry",
    },
    {
      img: aboutBanner,
      //   title: "Fleet Management",
      title: ngclogo,
      text: "NGC Express Logistics utilized state-of-the-art technology and transportation",
    },
  ];

  const imgStyles = {
    objectFit: "cover",
    width: "100%",
    height: "90vh",
  };
  const captionContainer = {
    position: "absolute",
    bottom: 130,
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };
  const container = {
    // marginTop: "65px",
  };
  return (
    <>
      <div style={container}>
        <Carousel>
          {bannerData.map((val, index) => {
            return (
              <Carousel.Item id="bannerContainer">
                <img
                  className="d-block w-100"
                  src={val.img}
                  alt="First slide"
                  style={imgStyles}
                />
                <Carousel.Caption style={captionContainer}>
                  {/* <h3 className="text-light  display-4  ">{val.title}</h3> */}
                  <img
                    src={val.title}
                    width="660px"
                    style={{ backgroundColor: "white" }}
                  />
                  <p className="w-75 lead bg-dark opacity-75">{val.text}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default BannerCarousel;
