import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import ngclogo from "../../assets/ngclogo.png";

const NavigationBar = () => {
  return (
    <div>
      <Navbar
        bg="light"
        variant="light"
        className="pt-3 shadow-sm opacity-75"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="#home">
            <img src={ngclogo} height="25" />
          </Navbar.Brand>
          {/* <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
          </Nav> */}
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
