import React from "react";
import "../css/home.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarsEquippments from "../../assets/CarsEquipments.jpg";
import IdolTaxi1 from "../../assets/IdolTaxi1.jpg";
import IdolTaxi2 from "../../assets/IdolTaxi2.jpg";
const Services = () => {
  const imgStyles = {
    objectFit: "cover",
    width: "100%",
    height: "50vh",
  };
  return (
    <div id="services" className=" pt-5 ">
      <Container>
        <Row>
          <Col md={6}>
            <img
              style={imgStyles}
              //   src="https://images.pexels.com/photos/5668906/pexels-photo-5668906.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              src={CarsEquippments}
            />
          </Col>
          <Col md={6} className="ps-5">
            {/* <h5>AREA OF OPERATIONS:</h5> */}
            <h4 className="w-75 mx-auto ">
              {" "}
              Materials Management, Distribution in Factories, Product
              Management
            </h4>
            <p>
              NGC Express Logistics utilized state-of-the-art technology and
              transportation. We are equipped with handheld mobile devices
              imcorporated with our field Management System. Our FMS is
              specifically designed to deliver real-time data from fleet,
              driver, delivery and asset management.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
