import React from "react";
import About from "./About";
import Banner from "./Banner";
import BannerCarousel from "./BannerCarousel";
import Clients from "./Clients";
import Services from "./Services";

const Home = () => {
  return (
    <div>
      {/* <Banner /> */}
      <BannerCarousel />
      <Services />

      <About />

      <Clients />
    </div>
  );
};

export default Home;
