import logo from "./logo.svg";
import "./App.css";
import NavigationBar from "./components/Navigation/NavigationBar";
import Home from "./components/Home/Home";

function App() {
  return (
    <>
      <NavigationBar />

      <div className="App">
        <Home />
      </div>
    </>
  );
}

export default App;
