import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import client1 from "../../assets/clientLogo/client1.png";
import client2 from "../../assets/clientLogo/client2.png";
import client3 from "../../assets/clientLogo/client3.png";
import client4 from "../../assets/clientLogo/client4.png";
import client5 from "../../assets/clientLogo/client5.png";
import client6 from "../../assets/clientLogo/client6.png";
import client7 from "../../assets/clientLogo/client7.png";
import client8 from "../../assets/clientLogo/client8.png";
import dummyImage from "../../assets/dummyImage.jpg";

const Clients = () => {
  const clientLogo = [
    { img: client1 },
    { img: client2 },
    { img: client3 },
    { img: client4 },
    { img: client5 },
    { img: client6 },
  ];

  const imgStyles = {
    objectFit: "cover",
    width: "100%",
    height: "100&",
  };
  return (
    <div className="mt-5">
      <h4 className="py-5 display-6">OUR CLIENTS</h4>

      <Container fuild>
        <Row>
          {clientLogo.map((val, index) => {
            return (
              <Col md={2} sm={2} style={{ height: "" }}>
                <Card className="  border-0 d-flex justify-content-center align-items-center">
                  <Card.Body>
                    <img src={val.img} style={imgStyles} />
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Clients;
