import React from "react";
import aboutBanner from "../../assets/aboutBanner.jpg";
import aboutBanner2 from "../../assets/aboutBanner2.png";
import Bus from "../../assets/Bus.jpg";
import miniTruck from "../../assets/miniTruck.png";
import Forklift from "../../assets/Forklift.png";
import Delivery from "../../assets/Delivery.jpeg";
import Container from "react-bootstrap/Container";
import "../css/home.css";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import IdolTaxi1 from "../../assets/IdolTaxi1.jpg";
import IdolTaxi2 from "../../assets/IdolTaxi2.jpg";
import CarTaxi from "../../assets/CarTaxi.png";

const About = () => {
  const data = [
    {
      img: aboutBanner2,
      title: "  Procuring Raw Materials and Parts",
      text: "   Procurement logistics is the flow of goods when the raw materials and parts necessary for manufacturing are procured from suppliers",
    },
    {
      img: Bus,
      title: "Shuttle Services",
      text: "Shuttle bus drivers utilize company-issued vehicles to drive passengers between places. Shuttle bus drivers transport various groups, including employees of the client companies.",
    },
    {
      img: Delivery,
      title: "Storage and Distribution",
      text: "Plan, direct, and coordinate the storage and distribution operations within an organization or the activities of organizations that are engaged in storing and distributing materials and products.",
    },
    // { img: IdolTaxi1, title: "", text: "" },
    // { img: IdolTaxi2, title: "", text: "" },
  ];
  const imgStyle = {
    objectFit: "cover",
    width: "100%",
    height: "200px",
  };
  const imgStyle2 = {
    objectFit: "cover",
    width: "80%",
    paddingBottom: "100px",
    marginBottom: "190px",
  };
  const parallax = {
    backgroundAttachment: "fixed",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  };
  return (
    <div className="mt-5 px-0">
      <Container>
        {/* <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <img
              src={IdolTaxi1}
              style={{ width: "300px", objectFit: "cover" }}
            />
          </Col>
        </Row> */}

        {/* <img src={IdolTaxi2} style={{ width: "300px" }} /> */}
      </Container>
      <h2 className="text-dark mt-5 mb-3">IDOL TAXI</h2>
      {/* <h2 className="text-dark mt-5 mb-3">RIDE HAILING SERVICES</h2> */}

      <p className="mb-5">
        Book via GMOVERS mobile app. We matches passengers with drivers. Service
        anywhere in Region IV-A
      </p>

      <Container fluid className=" myParallax px-0 my-auto" style={parallax}>
        <Row>
          <Col md={4} style={{ display: "flex", gap: 25 }}>
            <img
              src={IdolTaxi1}
              style={{
                width: "300px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Col>
          <Col md={4}>
            <img src={CarTaxi} />
          </Col>
        </Row>
      </Container>

      <Container fluid style={{ height: "240px", marginTop: "120px" }}>
        <Row>
          <Col md={4} sm={4} className="text-end">
            <h5>OUR CORE VALUES</h5>
            <h2 className="w-75 ms-auto">ABOUT US AND OUR SKILLS</h2>
          </Col>
          <Col md={4} sm={4}>
            <p className="about-lead lead  ps-5">
              NGC Express Logistics Inc. provides sophisticated and integrated
              transportation solutions directed to the needs of various
              logistics industries
            </p>
          </Col>
          <Col md={4} sm={4}>
            <img src={Forklift} style={imgStyle2} id="transform" />
          </Col>
        </Row>
      </Container>
      <Container className="my-4">
        <Row>
          {data.map((val) => {
            return (
              <Col md={4} sm={4}>
                <Card
                  className="border-0 p-0 bg-light"
                  style={{ height: "420px" }}
                >
                  <Card.Body>
                    <Card.Img src={val.img} style={imgStyle} />

                    <Card.Title className="pt-4">{val.title}</Card.Title>
                    <Card.Text>{val.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default About;
